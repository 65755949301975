import React from "react"

const Footer = ({isHome}) => (
  <div className="footer footer-section">
  <div className="text-center">
    <img className="sep" src={`${!isHome? '../': ''}images/sep-1@1x.svg`} height="79px"/>
  </div>
  <div className="container-fluid">
  <div className="row">
      <div className="col-12 col-sm-12 col-xl-2 col-lg-2 col-md-6 mt-sm-5 mt-5 mt-lg-0 mt-xl-0">
        <div className="row">
          <div className="col">
            <a href="https://shelterzoom.com" target="_blank"><img className="footer-logo" src={`${!isHome? '../': ''}images/sz-logo@2x.svg`} height="100"/></a>
          </div>
        </div>
        <div className="row pt-3">
        <div className="col-auto">
          <a className="lato-normal-black-16px text-muted" href="https://shelterzoom.com/privacy-policy/" target="_blank">Privacy Policy</a>
        </div>
        <div className="col-auto">
          <a className="lato-normal-black-16px text-muted" href="https://shelterzoom.com/tos/" target="_blank">Terms of Service</a>
        </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-xl-8 col-lg-6 col-md-6 mt-sm-5 mt-5 mt-lg-0 mt-xl-0">
        <div className="row justify-content-end">
          <div className="col-1 justify-content-end text-right">
            <img className="map-pin" src={`${!isHome? '../': ''}images/map-pin@2x.svg`}/>
          </div>
          <div className="col-11">
            <a className="lato-normal-black-18px" target="_blank" href="https://www.google.com/maps/place/14+Wall+St+20th+floor,+New+York,+NY+10005/data=!4m2!3m1!1s0x89c25a171850883b:0x1d7175272b6ff9c7?sa=X&ved=2ahUKEwis06etlbTzAhWZLc0KHZ3TBgcQ8gF6BAgOEAE">14 Wall Street, 20th Floor,<br />NY, New York, 10005</a>
          </div>
        </div>
        <div className="row justify-content-end mt-3">
          <div className="col-1 justify-content-end text-right">
            <img className="map-pin" src={`${!isHome? '../': ''}images/mobile-phone.svg`}/>
          </div>
          <div className="col-11">
            <p className="lato-normal-black-18px" >+1 (833) 710-9666 (Toll free: US & Canada) <br/>+1 (646) 290-5880 (International)</p>
          </div>
        </div>
        <div className="row justify-content-end mt-3">
          <div className="col-1 justify-content-end text-right">
            <img className="map-pin" src={`${!isHome? '../': ''}images/mail-icon.svg`}/>
          </div>
          <div className="col-11">
            <a className="lato-normal-firefly-18px" href="mailto:info@shelterzoom.com">info@shelterzoom.com</a>
          </div>
        </div>
      </div>
      {/*
      <div className="col-12 col-sm-12 col-xl-3 col-lg-4 col-md-10 mt-md-5 mt-sm-5 mt-5 mt-lg-0 mt-xl-0">
      <div className="row justify-content-end">

        <div className="col-4 justify-content-start">
          <div className="row">
            <div className="col-12">
              <a className="lato-bold-firefly-18px" href="#">Home</a>
            </div>
            <div className="col-12">
              <a className="lato-bold-firefly-18px" href="#">About</a>
            </div>
          </div>
        </div>

        <div className="col-4 justify-content-start">
          <div className="row">
            <div className="col-12">
              <a className="lato-bold-firefly-18px" href="#">Solutions</a>
            </div>
            <div className="col-12">
              <a className="lato-bold-firefly-18px" href="#">Latest</a>
            </div>
          </div>
        </div>

        <div className="col-4 justify-content-start">
          <div className="row">
            <div className="col-12">
              <a className="lato-bold-firefly-18px" href="#">Products</a>
            </div>
            <div className="col-12">
              <a className="lato-bold-firefly-18px" href="#">Contact</a>
            </div>
          </div>
        </div>

      </div>
      </div>
      */}
      <div className="col-4 col-sm-3 col-xl-1 col-lg-2 col-md-2 mt-md-5 mt-sm-5 mt-5 mt-lg-0 mt-xl-0">
        <div className="row">
        <div className="col-3">
          <a href="https://www.facebook.com/ShelterZoom/" target="_blank"><img className="social-footer-icon" src={`${!isHome? '../': ''}images/facebook@2x.svg`}/></a>
        </div>
        <div className="col-3">
          <a href="https://twitter.com/shelterzoom" target="_blank"><img className="social-footer-icon"  src={`${!isHome? '../': ''}images/twitter@2x.svg`}/></a>
        </div>
        <div className="col-3">
          <a href="https://www.instagram.com/shelterzoom/" target="_blank"><img className="social-footer-icon"  src={`${!isHome? '../': ''}images/instagram@2x.svg`}/></a>
        </div>
        <div className="col-3">
          <a href="https://www.linkedin.com/company/shelterzoom" target="_blank"><img className="social-footer-icon"  src={`${!isHome? '../': ''}images/linkedin@2x.svg`}/></a>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>

)

export default Footer
