import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Header from "./header";
import Footer from "./footer";
import config from '../../siteConfig.js'
// Styles
/* import "../../styles"; */
import "../../styles/scss/_style.scss";

import { initialFormLoad } from '../customscript'

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({isHome, children, authToken}) => {
      useEffect(() => {
        initialFormLoad();
    }, [])

    return (
        <>
            <Helmet>
                <html
                    lang={'eng'}
                    className="w-mod-js w-mod-touch hs-messages-mobile"
                />
                <body className="l-body" />
                <link
                    rel="alternate"
                    hreflang="en"
                    href="https://www.docuwalk.com"
                />
                <link
                    rel="alternate"
                    hreflang="ja"
                    href="https://ja.docuwalk.com"
                />
                <link
                    rel="alternate"
                    hreflang="tw"
                    href="https://tw.docuwalk.com"
                ></link>
                <script
                    type="text/javascript"
                    src="https://cdn.weglot.com/weglot.min.js"
                ></script>
                <meta property="og:image" content={"https://static.shelterzoom.com/images/sz-og.png"} />
                <meta property="og:image:width" content={config.shareImageWidth} />
                <meta property="og:image:height" content={config.shareImageHeight} />
            </Helmet>
            <div>
                {/* The main header section on top of the screen */}
                <Header isHome={isHome} authToken={authToken}/>

                <main id="site-main" className="site-main outer c-main">
                    {/* All the main content gets inserted here, index.js, post.js */}
                    {children}
                </main>

                <Footer isHome={isHome}/>
                {/* <script>{`${site.codeinjection_foot}`}</script> */}
            </div>
              <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/4639039.js" ></script>        
        </>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool
};

export default DefaultLayout;
