export const initialFormLoad = () => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);


    const chatBotScript = document.createElement("script")
    const portalId = "4639039"
    chatBotScript.src = "https://js.hs-scripts.com/" + portalId + ".js"
    document.body.appendChild(chatBotScript);

    script.addEventListener("load", () => {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })
        (window, document, 'script', 'dataLayer', 'GTM-M5L52PL');

    })
}
