import React, { useState, useEffect, useRef } from "react";

import 'bootstrap/dist/css/bootstrap.css';
import { Layout } from '../components/common'
import { Footer } from '../components/common'
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import ReactPlayer from 'react-player/lazy'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import NumberFormat from 'react-number-format';
const Index = ({ data, location, pageContext }) => {
  const [expanded, setExpanded] = useState(0);
  const [showPage, setShowPage] = useState(false);
  const [imgSrc, setImgSrc] = useState(".././images/1report-qr.png")
  const [campaign, setCampaign] = useState()
  const [authToken, setAuthToken] = useState()
  const [showDetails, setShowDetails] = useState(true)
  const [userData, setUserData] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [securityCode, setSecurityCode] = useState('')
  const [buttonValue, setButtonValue] = useState('Send Code')
  const [step, setStep] = useState('1')
  const [jwt, setJWT] = useState(null)
  const [userInfo, setUserInfo] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [registrationInfo, setRegistrationInfo] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [parsedData, setParsedData] = useState('')
  const [codeIsValid, setCodeIsValid] = useState(null)
  const [passwordValid, setPasswordValid] = useState(false)
  const [parseError, setParseError] = useState(false)
  const [showCodeError, setShowCodeError] = useState(false)

  const validatePassword = () => {
      const passwordValidation = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/);
      const isValid = passwordValidation.test(password2)
      if (isValid){
        if (password1 != password2){
          setPasswordValid(false)
          return false
        }
        else {
          setPasswordValid(true)
          return true
        }
      }
      else {
        setPasswordValid(false)
        return false
      }
  }

  const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
  };

  const isMount = useIsMount();

  useEffect(() => {

    setTimeout(() => setShowPage(true), 500);

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const campaign = urlParams.get('campaign')

    const token = urlParams.get('token')

    setCampaign(campaign)

    setAuthToken(token)

    if (token)submitToken(token)

    }, []);

  useEffect(() => {
    if (!isMount) {
      authenticateUser()
  }}, [parsedData]);

  useEffect(() => {
    if (!isMount) {
      getUserInfo()
  }}, [jwt]);

  useEffect(() => {
   validatePassword()
 }, [password1, password2]);

  const submitToken = async (token) => {

    var options = {
      method: 'POST',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
      'apiKey': `${process.env.REACT_APP_PROJECT_PARSE_TOKEN_KEY}`
      })
    }
    const res = await fetch(`${process.env.REACT_APP_PROJECT_PARSE_TOKEN_URI}`, options)
    if (res.status == 200){
      const data = await res.json()
      setParsedData(data)
    }
    else {
      setParseError(true)
    }
  }

  const authenticateUser = async (e) => {
      var options = {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          {
            email: parsedData.emailAddress,
            password: parsedData.password
          }
        )
      }
      const res = await fetch(`${process.env.REACT_APP_PROJECT_API_URI}/authority/login`, options)
      if (res.ok){
        const data = await res.json()
        setJWT(data.token)
      }
  }

  const getUserInfo = async (e) => {
      var options = {
        method: 'GET',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt}`,
        }
      }
      const res = await fetch(`${process.env.REACT_APP_PROJECT_API_URI}/account/me`, options)
      if (res.ok){
        const data = await res.json()
        setUserInfo(data.data[0].attributes.phone)
        setRegistrationInfo({
          data: {
            attributes: [

            ]
          }
        })
      }
  }

  const resetCode = async (e) => {
    e.preventDefault()
    setButtonValue('Loading...')
    var options = {
      method: 'PUT',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwt}`
      }
    }
    const res = await fetch(`${process.env.REACT_APP_PROJECT_API_URI}/account/resendPhoneCode`, options)
    if (res.ok){
      setStep(2)
      setButtonValue('Submit Password')
    }
  }

  const confirmResetCode = async (val) => {
    var options = {
      method: 'POST',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwt}`
      },
      body: JSON.stringify({data:{attributes:{code: val, step:"phone-verification"}}})
    }
    const res = await fetch(`${process.env.REACT_APP_PROJECT_API_URI}/registration/continue`, options)
    if (res.ok){
      setShowCodeError(false)
      setCodeIsValid(true)
    }
    else {
      setShowCodeError(true)
      setCodeIsValid(false)
      setShowCodeError(true)
    }
  }

  const changePassword = async () => {

    var options = {
      method: 'POST',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwt}`
      },
      body: JSON.stringify({new_password: password2, old_password: parsedData.password})
    }

    const res = await fetch(`${process.env.REACT_APP_PROJECT_API_URI}/authority/changePassword`, options)
    if (res.ok){
      hubSpotLifeCycle()
    }

  }

  const finalizeRegistration = async () => {
    var options = {
      method: 'POST',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwt}`
      },
      body: JSON.stringify({
        data: {
          attributes: {
            step: "profile",
            profile: {
              firstName: parsedData.first_name,
              lastName: parsedData.last_name,
              email: parsedData.emailAddress,
              phoneNumber: parsedData.phone,
              language: "eng",
              address: {
                addressLine: parsedData.address1,
                country: "US",
                state: parsedData.state,
                city: parsedData.city,
                zipCode: parsedData.zipcode
              }
            }
          }
        }
      })
    }
    const res = await fetch(`${process.env.REACT_APP_PROJECT_API_URI}/registration/continue`, options)
    if (res.ok){
      hubSpotLifeCycle()
    }
  }

  const hubSpotLifeCycle = async () => {
    setButtonValue('Loading...')
    var options = {
      method: 'PUT',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify({
      'apiKey': `${process.env.REACT_APP_PROJECT_PARSE_TOKEN_KEY}`
      })
    }
    const res = await fetch(`${process.env.REACT_APP_PROJECT_HUBSPOT_URI}/${parsedData.emailAddress}`, options)
    if (res.status == 204){
      setStep(3)
      setButtonValue('Go To Login')
    }
    else {
      setParseError(true)
    }
  }

  const testSecurityCode  = (val) => {
    setSecurityCode(val)
    if (val && val.length >= 6){
      confirmResetCode(val)
    }
    else {
      return false
    }
  }

  const setPassword = (field, value) => {

    if (field == 'original'){
      setPassword1(value)
    }
    else {
      setPassword2(value)
    }
  }

  return (
    <div className="fade-in-page" style={{ visibility: showPage ? "visible" : "hidden" }}>
      <div className="Index">
      <Layout isHome={true} authToken={authToken}>

      {
        authToken && step == '1' && (
            <div className="home main-conatiner container-fluid mb-5 pb-5">
              <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">

                <div className="row align-items-center justify-content-start">

                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <div className="row">
                      <div className="col-12">
                      <div className="products-heading pb-2">
                        Welcome to 1REport!
                      </div>
                        <div className="text-71 lato-normal-firefly-18px mt-3">
                          Please enter a new password to continue.
                        </div>
                      </div>

                    </div>
                    <div className="row mt-1">
                      <div className="col-12">
                        {/*<NumberFormat className="form-field" onPaste={(e) => testSecurityCode(e.target.value)} onChange={(e) => testSecurityCode(e.target.value)} placeholder="Security Code" format="######" mask="" />*/}
                        {
                          codeIsValid && (
                            <div className="px-3 pt-3" style={{color: '#2F8376'}}><p>Your security code is valid.</p></div>
                          )
                        }
                        {
                          showCodeError && securityCode != '' && (
                            <div className="px-3 pt-3" style={{color: 'red'}}><p>The security code you entered is incorrect. Please try again.</p></div>
                          )
                        }
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-6">
                        <input type="password"  onChange={(e) => setPassword('original', e.target.value)} className="form-field first-name lato-normal-firefly-18px" placeholder="New Password"></input>
                      </div>
                      <div className="col-6">
                        <input type="password" onChange={(e) => setPassword('confirm',e.target.value)} className="form-field first-name lato-normal-firefly-18px" placeholder="Confirm Password"></input>
                      </div>
                      {
                        passwordValid && (<div className="px-3 pt-4" style={{color: 'green'}}><p>Your password is valid.</p></div>)
                      }
                      {
                        passwordValid != null && !passwordValid && (<div className="px-3 pt-4"><p>Your password should contain a special character, have at least one uppercase, one lowercase, one number and be at least 8 characters long.</p></div>)
                      }

                    </div>

                  <div className="row justify-content-around mt-2">

                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-lg-6 col-xl-6 justify-content-end mt-5">

                            {passwordValid && (
                              <button href="#" onClick={changePassword} disabled={!passwordValid} className="products-btn"><span className="lato-bold-white-18px">Submit Password</span></button>
                            )}
                          </div>
                        </div>
                      </div>
                  </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mt-lg-5 pt-lg-5 mt-md-5 pt-md-5  mt-sm-5 pt-sm-5 mt-md-5 pt-md-5 mt-5 pt-5 mt-xl-1 pt-xl-1 mt-xxl-1 pt-xxl-1 text-center">
                    <img className="hero-image" height="100%" src='../images/1report-hero-2.svg'/>
                  </div>

                </div>

              </div>
            </div>
        )
      }
      {
        authToken && step == '3' && (
            <div className="home main-conatiner container-fluid mb-5 pb-5">
              <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">

                <div className="row align-items-center justify-content-start">

                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <div className="row">
                      <div className="col-12">
                      <div className="products-heading pb-2">
                        You password has been updated.
                      </div>
                        <div className="text-71 lato-normal-firefly-18px mt-3">
                          Your account is ready to use.
                        </div>
                      </div>

                    </div>

                  <div className="row justify-content-around mt-2">

                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-lg-6 col-xl-6 justify-content-end mt-5">
                          <a href="#" onClick={() => window.location = 'https://app.docuwalk.com'} className="products-btn"><span className="lato-bold-white-18px">{buttonValue}</span></a>
                          </div>
                        </div>
                      </div>
                  </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mt-lg-5 pt-lg-5 mt-md-5 pt-md-5  mt-sm-5 pt-sm-5 mt-md-5 pt-md-5 mt-5 pt-5 mt-xl-1 pt-xl-1 mt-xxl-1 pt-xxl-1 text-center">
                    <img className="hero-image" height="100%" src='../images/1report-hero-2.svg'/>
                  </div>

                </div>

              </div>
            </div>
        )
      }
      {!authToken && (

                  <div className="wrapper screen fade-in-page px-0 py-5 px-sm-0 px-md-5 px-lg-5 p-xl-5 p-xxl-5">

                  <div className="home main-conatiner container-fluid">

                  {/* Hero Section */}
                  <div id="home" className="row container-fluid hero-background">

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 mt-5 pt-5">

                    <div className="featured-section">
                      <div className="overlap-group35">
                        {/*HERO*/}
                        <div className="featured-text" >
                          <h1 className="heading-2">1REport{/* RM ACCENT <img className="text-accent"  src="./images/text-accent.svg" />*/}</h1>
                          <p className="heading-small mt-3">Smart QR and smart documents for self-employed and small businesses, powered by blockchain</p>
                          {
                            campaign === 'commpro_ad' && (
                              <a href="https://docuwalk.com/pricing" target="_blank" className="mt-3 products-btn"><span className="lato-bold-white-18px">Buy Now</span></a>
                            )
                          }
                        </div>
                      </div>

                    </div>


                    </div>

                  </div>

                  {/* Features Section */}

                  <div className="row text-center justify-content-center  px-3" >

                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 mt-3 hero-list ml-4">
                      <div className="row mt-3">
                        <div className="col">
                          <img src="./images/icon-worker.png" height="60"/>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col lato-medium-black-16px text-bold">
                          Smart Tools for Small Business and Independent Workers
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 mt-3 hero-list ">
                      <div className="row mt-3">
                        <div className="col">
                          <img src="./images/icon-platform.png" height="60"/>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col lato-medium-black-16px text-bold">
                          All-In-One Productivity Platform
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 mt-3 hero-list ">
                      <div className="row mt-3">
                        <div className="col">
                          <img src="./images/icon-marketing.png" height="60"/>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col lato-medium-black-16px text-bold">
                          Market Your Goods and Services
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 mt-3 hero-list ">
                      <div className="row mt-3">
                        <div className="col">
                          <img src="./images/icon-qr.png" height="60"/>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col lato-medium-black-16px text-bold">
                          Smart QR for Exclusive Lead Generation
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 mt-3 hero-list ">
                      <div className="row mt-3">
                        <div className="col">
                          <img src="./images/icon-documents.png" height="60"/>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col lato-medium-black-16px text-bold">
                          Intelligent Documents
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 mt-3 hero-list ">
                      <div className="row mt-3">
                        <div className="col">
                          <img src="./images/icon-blockchain.png" height="60"/>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col lato-medium-black-16px text-bold">
                          Blockchain Security
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 mt-3 hero-list ">
                      <div className="row mt-3">
                        <div className="col">
                          <img src="./images/icon-remote.png" height="60"/>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col lato-medium-black-16px text-bold">
                          Remote Work Tools
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 mt-3 hero-list ">
                      <div className="row mt-3">
                        <div className="col">
                          <img src="./images/icon-it.png" height="60"/>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col lato-medium-black-16px text-bold">
                          Codeless IT
                        </div>
                      </div>
                    </div>

                  </div>

                  {/* Video  Section */}

                  <div id="video" className="row align-items-center container-fluid justify-content-center mt-5 mb-5 pt-5 pb-5">
                    <p className="heading-3 lato-extra-bold-firefly-36px text-center mb-4">Tour 1REport's Features {/* RM ACCENT <img className="text-accent"  src="./images/text-accent.svg" />*/}</p>
                    <div className="col-md-12 col-lg-12 col-xl-12 col-12 col-sm-12">

                    <LiteYouTubeEmbed
                    id="h698B3liAw4"
                    title="DocuWalk - Overview"
                    />

                    </div>

                  </div>

                  {/* QR Section */}

                  <div id="qr" className="row align-items-center container-fluid justify-content-center mt-5 mb-5 pt-5 pb-5">

                    <div className="col-md-12 col-lg-12 col-xl-6">

                    <img  className="featured-hero" src="./images/cube.png" width="630" />

                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center align-items-center">
                    <div className="overlap-group16">
                      <div className="heading-3 lato-extra-bold-firefly-36px" >Turnkey QR {/* RM ACCENT <img className="text-accent"  src="./images/text-accent.svg" />*/}</div>
                      <p className="text-9 lato-normal-firefly-18px pt-3">
                        A fully operable QR code brings your clients into your marketing platform without sharing your leads with anyone else. Message them privately from within your 1REport document to start the conversation and continue the negotiation and document flow for the entire transaction all the way through contract signing.
                      </p>
                    </div>



                    </div>

                  </div>

                  {/* Brand Section */}

                  <div id="brand" className="row align-items-center container-fluid justify-content-center mt-5 mb-5 pt-5 pb-5">

                    <div className="col-md-12 col-lg-12 col-xl-6">

                    <div className="overlap-group16">
                      <div className="heading-3 lato-extra-bold-firefly-36px pt-0" >Market Your Brand {/* RM ACCENT <img className="text-accent"  src="./images/text-accent.svg" />*/}</div>
                      <p className="text-9 lato-normal-firefly-18px pt-3">
                      1REport lets you tell your story how you want to tell it. With more tools at your disposal and complete control over how you tell your story your brand reaches more people, more quickly.
                      </p>
                    </div>

                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center align-items-center">
                    <img  data-jarallax data-speed="0.2" className="jarallax featured-hero" src="./images/market.png" height="60%" />




                    </div>

                  </div>

                  {/* Smart Documents Section */}

                  <div id="documents" className="row align-items-center container-fluid justify-content-center mt-5 mb-5 pt-5 pb-5">

                    <div className="col-md-12 col-lg-12 col-xl-6">

                      <LiteYouTubeEmbed
                      id="BHQi2U7xlco"
                      title="DocuWalk - Overview"
                      />

                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center align-items-center">
                    <div className="overlap-group16">
                      <div className="heading-3 mt-0  pt-0 lato-extra-bold-firefly-36px" >Smart Documents {/* RM ACCENT <img className="text-accent"  src="./images/text-accent.svg" />*/}</div>
                      <p className="text-9 lato-normal-firefly-18px pt-3">
                    Documents do more in DocuWalk. Welcome to a new way to manage your documents. Instead of jumping between apps, keep your paper trail in the same place as your marketing tools, your message history, your attachments and anything else that accompanies a transaction. </p>
                    </div>



                    </div>

                  </div>

                  {/* Lead Gen Section */}

                  <div id="lead-gen" className="row align-items-center container-fluid justify-content-center mt-5 mb-5 pt-5 pb-5">

                    <div className="col-md-12 col-lg-12 col-xl-6">

                    <div className="overlap-group16">
                      <div className="heading-3 lato-extra-bold-firefly-36px" >Lead Generation {/* RM ACCENT <img className="text-accent"  src="./images/text-accent.svg" />*/}</div>
                      <p className="text-9 lato-normal-firefly-18px pt-3">
                        Find the right leads with targeted marketing materials that are easy to customize and adapt to the changing needs of your business. 
                      </p>
                    </div>

                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-6 d-flex mt-sm-4 mt-md-4 justify-content-center align-items-center">
                    <img  data-jarallax data-speed="0.2" className="jarallax featured-hero" src="./images/people.png" width="630" />




                    </div>

                  </div>

                  {/* Remote Operations Section */}

                  <div id="operations" className="row align-items-center container-fluid justify-content-center mt-5 mb-5 pt-5 pb-5">

                    <div className="col-md-12 col-lg-12 col-xl-6">

                    <img  data-jarallax data-speed="0.2" className="jarallax featured-hero" src="./images/remote-op.png" width="100%" />

                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center align-items-center">
                    <div className="overlap-group16">
                      <div className="heading-3 lato-extra-bold-firefly-36px" >Remote Operations {/* RM ACCENT <img className="text-accent"  src="./images/text-accent.svg" />*/}</div>
                      <p className="text-9 lato-normal-firefly-18px pt-3">
                    Make a seamless transition to the world of doing business remotely. Our turnkey QR code solution integrates directly into a full document and messaging platform. Carry out normal business operations without needing to be in the same room.   </p>
                    </div>



                    </div>

                  </div>

                  {/* VR Section */}

                  <div id="vr" className="row align-items-center container-fluid justify-content-center mt-5 mb-5 pt-5 pb-5">

                    <div className="col-md-12 col-lg-12 col-xl-6">

                    <div className="overlap-group16">
                      <div className="heading-3 lato-extra-bold-firefly-36px" >Virtual Negotiation Room {/* RM ACCENT <img className="text-accent"  src="./images/text-accent.svg" />*/}</div>
                      <p className="text-9 lato-normal-firefly-18px pt-3">
                        Negotiate in real-time in our private, secure Virtual Negotiation Room. Multiple layers of protection ensure your contracts haven't been altered without your knowing. Our multi-level permission controls, track changes, and Contracts Match feature give you peace of mind inside our platform.  
                      </p>
                    </div>

                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center align-items-center">
                    <img  data-jarallax data-speed="0.2" className="jarallax featured-hero" src="./images/esig.png" width="100%" />




                    </div>

                  </div>

                  {/* Blockchain E-Signature Section */}

                  <div id="esig" className="row align-items-center container-fluid justify-content-center mt-5 mb-5 pt-5 pb-5">

                    <div className="col-md-12 col-lg-12 col-xl-6">

                    <img  data-jarallax data-speed="0.2" className="jarallax featured-hero" src="./images/signature-hero.svg" width="100%" />

                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center align-items-center">
                    <div className="overlap-group16">
                      <div className="heading-3 lato-extra-bold-firefly-36px pt-0get" >Blockchain E-Signature {/* RM ACCENT <img className="text-accent"  src="./images/text-accent.svg" />*/}</div>
                      <p className="text-9 lato-normal-firefly-18px pt-3">
                      Know your contracts are protected with a blockchain signature. Unlimited blockchain signatures come with every price tier.
                    </p>
                    </div>



                    </div>

                  </div>

                  {/* Tokenization Section */}

                  <div id="tokenization" className="row align-items-center container-fluid justify-content-center mt-5 mb-5 pt-5 pb-5">

                    <div className="col-md-12 col-lg-12 col-xl-6">

                    <div className="overlap-group16">
                      <div className="heading-3 lato-extra-bold-firefly-36px" >Tokenization {/* RM ACCENT <img className="text-accent"  src="./images/text-accent.svg" />*/}</div>
                      <p className="text-9 lato-normal-firefly-18px pt-3">
                        The future of a digital world revolves around tokenization and secure digital documents. With tokenization built in to our system you know your documents are protected.  
                      </p>
                    </div>

                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center align-items-center">
                    <img  data-jarallax data-speed="0.2" className="jarallax featured-hero" src="./images/tokenization.png" width="100%" />




                    </div>

                  </div>

                  {/* Partners Section */}

                  <div className="row align-items-center container-fluid justify-content-around mb-5 pb-5">
                  <p className="heading-3 lato-extra-bold-firefly-36px text-center">Powered by ShelterZoom, as seen in:</p>
                  <div className="col-auto">
                    <img className="client-logo mt-5" src="images/logos/nyt.png" />
                  </div>
                    <div className="col-auto">
                      <img className="client-logo mt-5" src="images/logos/crains.png" />
                    </div>
                    <div className="col-auto">
                      <img className="client-logo mt-5" src="images/logos/fintech.png"/>
                    </div>
                    <div className="col-auto">
                      <img className="client-logo mt-5" src="images/logos/forbes.png" />
                    </div>
                    <div className="col-auto">
                      <img className="client-logo mt-5" src="images/logos/inman.png" />
                    </div>

                    <div className="col-auto">
                      <img className="client-logo mt-5" src="images/logos/ris-media.png" />
                    </div>
                    <div className="col-auto">
                      <img className="client-logo mt-5" src="images/logos/us-news.png" />
                    </div>

                  </div>

                  </div>

                  </div>
      )}
      {
        campaign && showDetails && (
          <div onClick={()=> setShowDetails(false)}style={{background: 'white', position: 'absolute', top: 0, right: 0, padding: 30, borderRadius: 10, border: '1px solid #ccc'}}>
            <p className="lato-normal-firefly-18px">
            Users Authentication Token: {authToken}
            <br/>
            Campaign Source: {campaign}
            </p>
          </div>
        )
      }

      </Layout>
      </div>
    </div>
  );
}

export default Index;
