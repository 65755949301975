import PropTypes from "prop-types"
import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu'
const Header = ({ site, isHome, authToken }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => {
    setIsOpen(true)
  }

  return (
    <>
    {
      !authToken && (
        <Menu isOpen={ isOpen } width={ '80%' } right onClose={ () => setIsOpen(false) }>
        <div className="slide-out-content">
        <a href="#"><div className="mx-1 menu-dropdown-img"><img src="/images/menu-icons/real-estate-icon.svg"/></div> <div className="menu-dropdown-text">Home</div></a>
        <a href="https://app.docuwalk.com/login" target="_blank"><div className="mx-1 menu-dropdown-img"><img src="/images/login.png"/></div> <div className="menu-dropdown-text">Sign In</div></a>
        <a href="https://docuwalk.com/pricing" target="_blank"><div className="mx-1 menu-dropdown-img"><img src="/images/get-started.png"/></div> <div className="menu-dropdown-text">Buy Now</div></a>

        <p className="menu-header mt-5">Solutions</p>
          <a href="#qr" className="mx-1"><div className="menu-dropdown-img"><img src="/images/menu-icons/privacy-icon.svg"/></div><div className="menu-dropdown-text ">Turnkey QR</div></a>
          <a href="#brand" className="mx-1"><div className="menu-dropdown-img"><img src="/images/menu-icons/marketing-icon.svg"/></div><div className="menu-dropdown-text ">Market Your Brand</div></a>
          <a href="#lead-gen" className="mx-1"><div className="menu-dropdown-img"><img src="/images/menu-icons/blockchain-icon.svg"/></div><div className="menu-dropdown-text ">Lead Generation</div></a>
          <a href="#operations" className="mx-1"><div className="menu-dropdown-img"><img src="/images/menu-icons/vr-icon.svg"/></div><div className="menu-dropdown-text ">Remote Operations</div></a>
          <a href="#vr" className="mx-1"><div className="menu-dropdown-img"><img src="/images/menu-icons/code-icon.svg"/></div><div className="menu-dropdown-text ">Virtual Negotiation Room</div></a>
          <a href="#esig" className="mx-1"><div className="menu-dropdown-img"><img src="/images/menu-icons/code-icon.svg"/></div><div className="menu-dropdown-text ">E-Signature</div></a>
        </div>
        </Menu>
      )
    }

    <div className="header row px-5 ">
      <div className="col-4 col-sm-4 col-md-4 col-lg-6 col-xl-6 col-xxl-6">
      <a href="../">
        <img className="sz-logo" src={'images/one-report-logo-powered.svg'} />
      </a>
      </div>
      {
        !authToken && (
          <div className="col-8 col-sm-8 col-md-8 col-lg-6 col-xl-6 col-xxl-6">
          <div className="header-container row ">
            <nav>
              <ul className="mb-0 px-0">
              <li className="d-none d-sm-none d-md-none d-lg-none d-xl-inline d-xxl-inline"><a className="nav-link-header" href="#">Home</a></li>
              <li className="d-none d-sm-none d-md-none d-lg-none d-xl-inline d-xxl-inline">
                <div className="dropdown nav-link-header">
                  <button className="dropbtn">Solutions</button>
                  <div className="dropdown-content">
                    <div className="flex-container">

                    <div className="flex-child">
                      <p className="menu-header">Solutions</p>
                      <a href="#qr"><div className="menu-dropdown-img"><img src="/images/menu-icons/privacy-icon.svg"/></div> <div className="menu-dropdown-text">Turnkey QR</div></a>
                      <a href="#brand"><div className="menu-dropdown-img"><img src="/images/menu-icons/marketing-icon.svg"/></div> <div className="menu-dropdown-text">Market Your Brand</div></a>
                      <a href="#lead-gen"><div className="menu-dropdown-img"><img src="/images/menu-icons/blockchain-icon.svg"/></div> <div className="menu-dropdown-text">Lead Generation</div></a>
                      <a href="#operations"><div className="menu-dropdown-img"><img src="/images/menu-icons/vr-icon.svg"/></div> <div className="menu-dropdown-text">Remote Operations</div></a>
                      <a href="#vr"><div className="menu-dropdown-img"><img src="/images/menu-icons/code-icon.svg"/></div> <div className="menu-dropdown-text">Virtual Negotiation Room</div></a>
                      <a href="#esig"><div className="menu-dropdown-img"><img src="/images/menu-icons/code-icon.svg"/></div> <div className="menu-dropdown-text">E-Signature</div></a>
                      {/*<a href="#"><div className="menu-dropdown-img"><img src="/images/menu-icons/gps-icon.svg"/></div> <div className="menu-dropdown-text">Document GPS</div></a>
                      <a href="#"><div className="menu-dropdown-img"><img src="/images/menu-icons/smart-city-icon.svg"/></div> <div className="menu-dropdown-text">Smart City</div></a>
                      <a href="#"><div className="menu-dropdown-img"><img src="/images/menu-icons/social-icon.svg"/></div> <div className="menu-dropdown-text">Social Impact</div></a>*/}
                    </div>
                    </div>

                  </div>
                </div>
              </li>
                <li className="d-none d-sm-none d-md-none d-lg-inline d-xl-inline d-xxl-inline"><a className="nav-link-header" target="_blank" href="https://app.docuwalk.com/login">Sign In</a></li>
                <li className="get-started-btn d-none d-sm-none d-md-none d-lg-inline d-xl-inline d-xxl-inline"><a className="nav-btn" target="_blank" href="https://docuwalk.com/pricing">Buy Now</a></li>
                <li className="social-icon mr-2 d-inline d-sm-inline d-md-inline d-lg-inline d-xl-none d-xxl-none"><a href="#" onClick={() => handleOpen()}> <img className="hamburger" src={`../images/nav-ham.svg`} /></a></li>
                <li className="social-icon d-inline d-sm-inline d-md-inline d-lg-none d-xl-none d-xxl-none"><a href="#" className="nav-link-header" onClick={() => handleOpen()} href="#">Menu</a></li>
              </ul>
            </nav>
          </div>
          </div>
        )
      }

    </div>
    </>
  )
}


Header.propTypes = {
  site: PropTypes.string,
}

export default Header
